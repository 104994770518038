import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import TwoImages from "../../components/Layout/TwoImages";

const Travertino = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Travertino
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        Travertino is a natural stone that is quarried from limestone deposits.
        It is a type of marble that is characterized by its porous, chalky
        appearance and is often used in architectural and decorative projects.
        It is a versatile stone that can be used for flooring, cladding,
        countertops, and other surfaces. It is available in a variety of colors,
        including beige, white, and pink, and it can be polished, honed, or
        tumbled to create different finishes. Travertino is a durable and
        long-lasting material that is resistant to wear and tear, and it can be
        easily cleaned and maintained.
      </p>
      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_1_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_2_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_5_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_4_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_6_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_7_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_3_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_9_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_8_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_10_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_11_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_12_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_13_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_14_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
    </div>
  );
};

export default Travertino;
